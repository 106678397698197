/* eslint-disable no-param-reassign */
<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <!-- <main>
      <div id="construction" class="txt-center txt-light_green" style="padding: 15% 0">
        <h1 class="construction_msg">
          <font-awesome-icon icon="hammer" />
          建置中...
          <font-awesome-icon icon="wrench" />
        </h1>
      </div>
    </main> -->

    <main>
      <section id="form_detail">
        <div class="w1300">

          <!-- 表單主標題 -->
          <div class="form_main_title_box">
            <h3 class="form_main_title txt-bold">費用查詢</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <form id="form_account_search_fee" @submit.prevent>

            <div class="filter_box payment_status">
              <select
                id="payment_status"
                name="payment_status"
                v-model="params.pay_status"
                @change="filterData()"
              >
                <option value="" disabled selected>篩選繳費狀態</option>
                <option value="-1">全部</option>
                <option value="0">未繳費</option>
                <option value="1">已繳費</option>
              </select>
            </div>

            <!-- table area -->
            <div class="table_box">

              <!-- select all for mobile -->
              <!-- <div class="checkbox_container checkbox_container-mobile">
                <label for="check_all_mobile" class="th">
                  <input
                    id="check_all_mobile"
                    name="check_all_mobile"
                    type="checkbox"
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  >
                  <span class="checkmark"></span>
                </label>
              </div> -->

              <table class="txt-center">
                <thead>
                  <tr>
                    <!-- <th class="td-checkbox th">

                      select all for desktop
                      <div class="checkbox_container">
                        <label for="check_all_desktop" class="th">
                          <input
                            id="check_all_desktop"
                            name="check_all_desktop"
                            type="checkbox"
                            v-model="selectAll"
                            @change="toggleSelectAll"
                          >
                          <span class="checkmark"></span>
                        </label>
                      </div>

                    </th> -->
                    <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                    <th class="min100"><p>{{ th2 }}</p></th>
                    <th class="min100"><p>{{ th3 }}</p></th>
                    <th class="min150"><p>{{ th4 }}</p></th>
                    <th class="min100"><p>{{ th5 }}</p></th>
                    <th class="min100"><p>{{ th6 }}</p></th>
                  </tr>
                </thead>
                <tbody id="table_data">
                  <tr v-for="tr in tableData" :key="tr.id">
                    <!-- <td
                      data-title="繳費"
                      class="td-checkbox"
                    >
                      <div class="checkbox_container" v-if="tr.status === '未繳費'">
                        <label :for="tr.id">
                          <input
                            :id="tr.id"
                            v-model="tr.checked"
                            class="check_item"
                            type="checkbox"
                            @change="cancelSelectAll(tr.id, tr.checked)"
                          >
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </td> -->
                    <td :data-title="th1" class="td-main txt-left">
                      <p v-for="item in tr.order_details" :key="item.uuid">
                        {{ item.content }}
                      </p>
                    </td>
                    <td :data-title="th2"><p>{{ tr.year }}</p></td>
                    <td :data-title="th3"><p>{{ tr.total }}</p></td>
                    <td :data-title="th4" class="td-input">
                      <p v-for="item in tr.order_details" :key="item.uuid">
                        {{ item.title }}
                      </p>
                      <!-- <template v-if="tr.status === '未繳費'">
                        <input
                          v-model.trim="tr.receiptTitle"
                          type="text"
                          placeholder="請輸入收據抬頭"
                        >
                      </template>
                      <template v-else-if="tr.status === '已繳費'">
                        <p>{{ tr.receiptTitle }}</p>
                      </template> -->
                    </td>
                    <td :data-title="th5" class="min100"><p>{{ statusConverter(tr) }}</p></td>
                    <td :data-title="th6" class="td-download-multiple">
                      <template v-if="statusConverter(tr) === '已繳費'">
                        <button
                          v-for="item in tr.order_details"
                          :key="item.uuid"
                          type="button"
                          class="small"
                          @click="updateReceiptInfo(item.uuid)"
                        >下載收據</button>
                      </template>
                      <template v-else>
                        <button
                          type="button"
                          class="small payment_fail_btn"
                          @click="goToPay(tr)"
                        >前往繳費</button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- <div class="payment_check txt-right">
              <p class="payment_check_total">
                總金額 <span class="txt-light_green">{{ totalAmount }}</span> 元
              </p>
              <div class="filter_box payment_check_container">
                <Select
                  id="pay_type"
                  v-model="user.paymentType"
                  :item="inputFormat.paymentSelect"
                  :rules="[
                    val => !!val || '必選欄位！',
                  ]"
                />

                <button
                  type="button"
                  class="btn p"
                  @click="onSubmit"
                >前往繳費</button>
              </div>
            </div> -->
          </form>
        </div>

        <Pagination
          :pageButton="pageButton"
          :page="params.page"
          :totalPage="totalPage"
          @updatePage="updatePage"
        />
      </section>
    </main>
  </div>
</template>

<script>
// Library
import _ from 'lodash';

// Components
import PageSubHeader from '@/components/PageSubHeader.vue';
import Select from '@/components/form/Select.vue';
import Pagination from '@/components/Pagination.vue';

// Functions
import { AccountSearchSubHeader } from '@/lib/const';
import { orderList, downloadReceipt } from '@/lib/http';
// eslint-disable-next-line no-unused-vars
import { request, toFormData } from '@/boot/axios';

export default {
  name: 'AccountSearchFee',
  data() {
    return {
      /** Static Data */
      ...AccountSearchSubHeader,
      pageButton: 5,
      selectAll: false,
      th1: '費用名稱',
      th2: '年度',
      th3: '金額',
      th4: '收據抬頭',
      th5: '繳費狀態',
      th6: '操作',

      /** Search Params */
      params: {
        pay_status: '',
      },

      /** Axios Data */
      totalPage: 1,
      tableData: [],

      // user: {
      //   name: '王小明',
      //   paymentType: '',
      //   paymentTarget: [],
      // },
      // inputFormat: {
      //   paymentSelect: {
      //     placeHolder: '請選擇繳費方式',
      //     options: [
      //       '信用卡',
      //       // 'ATM轉帳',
      //       // '超商代碼繳費',
      //     ],
      //   },
      // },
    };
  },
  created() {
    if (this.$route.query.pay_status) {
      this.params.pay_status = this.$route.query.pay_status;
    }
    if (this.$route.query.num) {
      this.params.page = parseInt(this.$route.query.num, 10);
    }
    this.filterDataInit(this.params.page);
  },
  computed: {
    // totalAmount() {
    //   let computedPrice = 0;
    //   this.tableData.forEach((data) => {
    //     if (data.checked && data.status === '未繳費') {
    //       computedPrice += data.price;
    //     }
    //   });
    //   return computedPrice;
    // },
  },
  methods: {
    statusConverter(order) {
      if (_.get(order, 'payment.completed', null)) {
        return '已繳費';
      }
      return '未繳費';
    },
    /** 收據資料存進cookies */
    updateReceiptInfo(uuid) {
      downloadReceipt({
        order_detail_uuid: uuid,
      }).then((result) => {
        if (result.data.status) {
          this.$cookies.set('receiptInfo', JSON.stringify(result.data.result.data));
          const routeData = this.$router.resolve({ path: '/receipt' });
          window.open(routeData.href, '_blank');
        } else {
          this.openModal({
            message: result.data.message,
          });
        }
      });
    },
    goToPay(data) {
      const orderID = _.get(data, 'uuid', '');
      if (orderID) {
        this.$router.push({ path: `/account/search/payment?order_id=${orderID}` });
      } else {
        this.openModal({
          message: '尚未產生未繳費訂單',
        });
      }
    },
    filterDataInit(pageValue = 1) {
      if (parseInt(this.params.pay_status, 10) !== 0
        && parseInt(this.params.pay_status, 10) !== 1) {
        orderList({
          page: pageValue,
        }).then((result) => {
          if (result.data.status) {
            this.tableData = result.data.result.data;
            this.totalPage = result.data.result.pageCount;
            this.params.page = pageValue;
            this.$router.replace({
              query: {
                pay_status: this.params.pay_status,
                num: pageValue,
              },
            });
          }
        });
      } else {
        orderList({
          ...this.params,
          page: pageValue,
        }).then((result) => {
          if (result.data.status) {
            this.tableData = result.data.result.data;
            this.totalPage = result.data.result.pageCount;
            this.params.page = pageValue;
            this.$router.replace({
              query: {
                pay_status: this.params.pay_status,
                num: pageValue,
              },
            });
          }
        });
      }
    },
    filterData(pageValue = 1) {
      if (parseInt(this.params.pay_status, 10) !== 0
        && parseInt(this.params.pay_status, 10) !== 1) {
        orderList({
          page: pageValue,
        }).then((result) => {
          if (result.data.status) {
            this.tableData = result.data.result.data;
            this.totalPage = result.data.result.pageCount;
            this.params.page = pageValue;
            this.$router.push({
              query: {
                pay_status: this.params.pay_status,
                num: pageValue,
              },
            });
          }
        });
      } else {
        orderList({
          ...this.params,
          page: pageValue,
        }).then((result) => {
          if (result.data.status) {
            this.tableData = result.data.result.data;
            this.totalPage = result.data.result.pageCount;
            this.params.page = pageValue;
            this.$router.push({
              query: {
                pay_status: this.params.pay_status,
                num: pageValue,
              },
            });
          }
        });
      }
    },
    updatePage(value) {
      // this.params.page = value;
      // this.$router.push({ query: { num: value } });
      this.filterData(value);
    },
    // toggleSelectAll() {
    //   if (this.selectAll === true) {
    //     this.tableData.forEach((item) => {
    //       // eslint-disable-next-line no-param-reassign
    //       item.checked = true;
    //     });
    //     this.user.paymentTarget = this.tableData.filter((data) => data.status === '未繳費');
    //   } else {
    //     this.tableData.forEach((data) => {
    //       // eslint-disable-next-line no-param-reassign
    //       data.checked = false;
    //     });
    //     this.user.paymentTarget = [];
    //   }
    // },
    // cancelSelectAll(dataId, dataChecked) {
    //   if (this.selectAll === true) {
    //     this.selectAll = false;
    //   }
    //   if (dataChecked === true) {
    //     this.tableData.forEach((data) => {
    //       if (data.id === dataId) {
    //         this.user.paymentTarget.push(data);
    //       }
    //     });
    //   } else {
    //     this.user.paymentTarget
    // = this.user.paymentTarget.filter((target) => target.id !== dataId);
    //   }
    // },
    // onSubmit() {
    //   const errorList = [];
    //   this.$children.forEach((item) => {
    //     if (item.$options.name && item.$options.name.match(/^Form/i)) {
    //       item.test();
    //       errorList.push(item.error);
    //     }
    //   });
    //   if (errorList.indexOf(true) === -1) {
    //     request({
    //       url: 'API',
    //       method: 'post',
    //       data: toFormData(this.user),
    //     });
    //     // axios.post('https://my-json-server.typicode.com/typicode/demo/posts', this.user)
    //     //   .then((res) => {
    //     //     console.log('success', res.data);
    //     //     if (this.user.paymentType === '信用卡') {
    //     //       // this.$router.push({ path: '/account/search/fee' });
    //     //     }
    //     //   })
    //     //   .catch((error) => {
    //     //     console.log(error);
    //     //   });
    //   } else {
    //     // console.log('必填欄位尚未完成！');
    //   }
    // },
  },
  watch: {
    /**
     * 偵測user使用網頁的上一頁功能時，重設頁數及重call API
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      const queryNum = parseInt(this.$route.query.num, 10);
      if (queryNum && queryNum > 0) {
        this.params.page = parseInt(this.$route.query.num, 10);
        this.filterData(this.params.page);
      }
    },
  },
  components: {
    PageSubHeader,
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    Select,
  },
};
</script>
